import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "services/api/Api";
import httpErrorHandler from "services/api/httpErrorHandler";

export const getDeliveryOrders = createAsyncThunk(
  "deliveryOrder/getDeliveryOrders",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/deliveryorders");
      console.log("getDeliveryOrders", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("getDeliveryOrders error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("getDeliveryOrders errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const getTransferOrders = createAsyncThunk(
  "deliveryOrder/getTransferOrders",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/deliveryorders/transfer");
      console.log("getTransferOrders", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("getTransferOrders error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("getTransferOrders errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const createDeliveryOrder = createAsyncThunk(
  "deliveryOrder/createDeliveryOrder",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    try {
      const { data } = await Api.post(`/deliveryorders`, info);
      console.log("createDeliveryOrder", JSON.stringify(data));
      return fulfillWithValue(data);
    } catch (error) {
      console.log("createDeliveryOrder error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("createDeliveryOrder errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const getDeliveryOrderByDoNum = createAsyncThunk(
  "deliveryOrder/getDeliveryOrderByDoNum",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/deliveryorders/" + info.doNumber);
      console.log("getDeliveryOrderByDoNum", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("getDeliveryOrderByDoNum error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("getDeliveryOrderByDoNum errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const getIncompleteDoByProductId = createAsyncThunk(
  "deliveryOrder/getIncompleteDoByProductId/",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/deliveryorders/product/" + info.id);
      console.log("getIncompleteDoByProductId", data);  // [{}]
      return fulfillWithValue(data);
    } catch (error) {
      console.log("getIncompleteDoByProductId error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("getIncompleteDoByProductId errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const cancelDeliveryOrder = createAsyncThunk(
  "deliveryOrder/cancelDeliveryOrder",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.patch("/deliveryorders/cancelDO", info);
      console.log("cancelDeliveryOrder", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("cancelDeliveryOrder error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("cancelDeliveryOrder errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);


const deliveryOrderSlice = createSlice({
  name: "deliveryOrder",
  initialState: {
    successMessage: "",
    errorMessage: "",
    saveDoSuccessMessage: "",
    saveDoErrorMessage: "",
    loading: false,
    selectedDO: {},
    deliveryOrderList: [],
    transferOrderList: [],
    doProductList: [],
    total: 0,
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.saveDoSuccessMessage = "";
      state.saveDoErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeliveryOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeliveryOrders.rejected, (state, { payload }) => {
        state.loading = false;
        // state.errorMessage = payload.error;
      })
      .addCase(getDeliveryOrders.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = payload.message;
        state.total = payload.total;
        state.deliveryOrderList = payload.data;
      })
      .addCase(getTransferOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTransferOrders.rejected, (state, { payload }) => {
        state.loading = false;
        // state.errorMessage = payload.error;
      })
      .addCase(getTransferOrders.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = payload.message;
        state.total = payload.total;
        state.transferOrderList = payload.data;
      })
      .addCase(createDeliveryOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDeliveryOrder.rejected, (state, { payload }) => {
        state.loading = false;
        state.saveDoErrorMessage = payload.error;
      })
      .addCase(createDeliveryOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.saveDoSuccessMessage = payload.message;
        state.selectedDO = payload.data;
      })
      .addCase(getDeliveryOrderByDoNum.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeliveryOrderByDoNum.rejected, (state, { payload }) => {
        state.loading = false;
        // state.errorMessage = payload.error;
      })
      .addCase(getDeliveryOrderByDoNum.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = payload.message;
        state.selectedDO = payload.data;
      })
      .addCase(getIncompleteDoByProductId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIncompleteDoByProductId.rejected, (state, { payload }) => {
        state.loading = false;
        // state.errorMessage = payload.error;
      })
      .addCase(getIncompleteDoByProductId.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.doProductList = payload.data;
      })
      .addCase(cancelDeliveryOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelDeliveryOrder.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(cancelDeliveryOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
      })

  },
});

export const { messageClear } = deliveryOrderSlice.actions;
export default deliveryOrderSlice.reducer;